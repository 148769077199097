import React, { useState, useEffect, useRef } from "react";
import layout from "./assets/layout.jpg";
import {
  useAcTempSensorMapMutation,
  useGetBranchWiseAcListQuery,
} from "../../../features/ac/acBranchWiseAcDashboard";
import { useAcSensorMapMutation } from "../../../features/ac/acBranchWiseAcDashboard";

import { useGetAcSensorListQuery } from "../../../features/ac/acBranchWiseAcDashboard";
import useTitle from "../../../hooks/useTitle";

function AcSensorMap() {
  useTitle("Sensor Map");
  const [imageSize, setImageSize] = useState({ width: 0, height: 0 });
  const layoutRef = useRef(null);
  const [acs, setAcs] = useState([]);
  const [sensors, setSensors] = useState([]);
  const [draggingItem, setDraggingItem] = useState(null); // Can be AC or sensor
  const [startPos, setStartPos] = useState({ x: 0, y: 0 });
  const [draggingType, setDraggingType] = useState(null); // Tracks whether dragging an AC or sensor

  const { data: branchWiseAcList, isLoading } =
    useGetBranchWiseAcListQuery("1"); // Hardcoded branch id
  const { data: branchWiseSensorList, isLoading: sensorListLoading } =
    useGetAcSensorListQuery("1");

  const [acSensorMap] = useAcSensorMapMutation();
  const [acTempSensorMap] = useAcTempSensorMapMutation(); // To update sensor position

  // Fetch AC and sensor data when loading completes
  useEffect(() => {
    const img = new Image();
    img.src = layout;
    img.onload = () => {
      setImageSize({ width: img.width, height: img.height });
    };

    // Fetch and set AC data
    if (!isLoading) {
      const acsWithCoordinates = branchWiseAcList.map((ac) => ({
        ...ac,
        x: ac.x ?? 100, // Default x position if null
        y: ac.y ?? 100, // Default y position if null
      }));
      setAcs(acsWithCoordinates);
    }

    // Fetch and set sensor data
    if (!sensorListLoading) {
      const sensorsWithCoordinates = branchWiseSensorList.map((sensor) => ({
        ...sensor,
        x: sensor.x ?? 200, // Default x position if null
        y: sensor.y ?? 200, // Default y position if null
      }));
      setSensors(sensorsWithCoordinates);
    }
  }, [isLoading, sensorListLoading]);

  // Handle dragging start
  const handleMouseDown = (type, index, e) => {
    setDraggingItem(index);
    setDraggingType(type); // Track whether dragging an AC or sensor
    if (type === "AC") {
      setStartPos({
        x: e.clientX - acs[index].x,
        y: e.clientY - acs[index].y,
      });
    } else if (type === "sensor") {
      setStartPos({
        x: e.clientX - sensors[index].x,
        y: e.clientY - sensors[index].y,
      });
    }
  };

  // Handle dragging movement
  const handleMouseMove = (e) => {
    if (draggingItem === null) return;

    const newX = e.clientX - startPos.x;
    const newY = e.clientY - startPos.y;

    if (draggingType === "AC") {
      setAcs((prevAcs) =>
        prevAcs.map((ac, i) =>
          i === draggingItem ? { ...ac, x: newX, y: newY } : ac
        )
      );
    } else if (draggingType === "sensor") {
      setSensors((prevSensors) =>
        prevSensors.map((sensor, i) =>
          i === draggingItem ? { ...sensor, x: newX, y: newY } : sensor
        )
      );
    }
  };

  // Handle drag end and save position
  const handleMouseUp = async () => {
    if (draggingItem === null) return;

    if (draggingType === "AC") {
      const draggedAc = acs[draggingItem];

      // Save the updated position (x, y) for AC
      acSensorMap({
        id: draggedAc.peripheral_unit_id,
        x: draggedAc.x,
        y: draggedAc.y,
      });
    } else if (draggingType === "sensor") {
      const draggedSensor = sensors[draggingItem];

      // Save the updated position (x, y) for sensor
      acTempSensorMap({
        id: draggedSensor.peripheral_unit_id,
        x: draggedSensor.x,
        y: draggedSensor.y,
      });
    }

    setDraggingItem(null); // Stop dragging
    setDraggingType(null); // Reset dragging type
  };

  // const getHeatColor = (temp) => {
  //   if (temp <= 20) return "rgba(0, 0, 255, 0.3)"; // Cool: Blue
  //   if (temp <= 35) return "rgba(0, 255, 0, 0.3)"; // Medium: Green
  //   return "rgba(255, 0, 0, 0.3)"; // Hot: Red
  // };
  const getHeatMapStyle = (temp) => {
    if (temp <= 28) {
      return {
        boxShadow:
          "0 0 30px 10px rgba(0, 255, 0, 0.3), 0 0 60px 30px rgba(0, 255, 0, 0.2)",
      }; // Medium: Green with gradient-like shadow
    } else if (temp <= 30) {
      return {
        boxShadow:
          "0 0 30px 10px rgba(243, 243, 10, 0.317), 0 0 60px 30px rgba(248, 248, 6, 0.2)",
      }; // Cool: Blue with gradient-like shadow
    } else {
      return {
        boxShadow:
          "0 0 30px 10px rgba(255, 0, 0, 0.3), 0 0 60px 30px rgba(255, 0, 0, 0.2)",
      }; // Hot: Red with gradient-like shadow
    }
  };
  return (
    <div
      className="App"
      style={{
        width: `${imageSize.width}px`,
        height: `${imageSize.height}px`,
        position: "relative",
        overflow: "hidden",
        backgroundImage: `url(${layout})`,
        backgroundSize: "auto",
        backgroundPosition: "center",
      }}
      ref={layoutRef}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
    >
      {/* Render ACs dynamically */}
      {acs.map((ac, index) => (
        <div>
          <div
            key={ac?.peripheral_unit_id}
            onMouseDown={(e) => handleMouseDown("AC", index, e)}
            style={{
              width: "60px",
              height: "60px",
              color: "white",
              backgroundColor: "gray",
              border: "2px solid black",
              borderRadius: "50%",
              position: "absolute",
              left: `${ac.x}px`,
              top: `${ac.y}px`,
              cursor: "grab",
              zIndex: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                width: "30px",
                height: "30px",
                fontWeight: "bold",
                position: "absolute",
                // left: `${ac.x}px`,
                // top: `${ac.y + 35}px`,
                top: "0px",
                zIndex: 2,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                // Add the heat map effect
              }}
            >
              AC:{ac?.peripheral_number}
            </div>
            <div
              style={{
                width: "30px",
                height: "30px",
                fontWeight: "bold",
                position: "absolute",
                // left: `${ac.x}px`,
                // top: `${ac.y + 35}px`,
                top: "20px",
                zIndex: 2,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                // Add the heat map effect
              }}
            >
              <div>{ac?.temp !== null ? `${ac?.temp}°C` : "N/A"}</div>
            </div>
          </div>
        </div>
      ))}

      {/* Render Sensors dynamically */}
      {sensors.map((sensor, index) => (
        <div key={sensor.peripheral_unit_id}>
          <div
            onMouseDown={(e) => handleMouseDown("sensor", index, e)}
            style={{
              width: "60px", // Adjust sensor size as needed
              height: "60px",
              backgroundColor: "#fff", // Keep the base color neutral
              border: "2px solid black",
              borderRadius: "50%",
              position: "absolute",
              left: `${sensor.x}px`,
              top: `${sensor.y}px`,
              cursor: "grab",
              zIndex: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              ...getHeatMapStyle(sensor.sensor_value), // Add the heat map effect
            }}
          >
            <div
              style={{
                width: "30px",
                height: "30px",
                fontWeight: "bold",
                position: "absolute",
                // left: `${sensor.x}px`,
                // top: `${sensor.y + 25}px`,
                top: "0px",
                zIndex: 2,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                // Add the heat map effect
              }}
            >
              S:{sensor.slave_number}
            </div>
            <div
              style={{
                width: "30px",
                height: "30px",
                fontWeight: "bold",
                position: "absolute",
                // left: `${sensor.x}px`,
                // top: `${sensor.y + 25}px`,
                top: "20px",
                zIndex: 2,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                // Add the heat map effect
              }}
            >
              {/* {sensor.sensor_value}°C */}
              {sensor.sensor_value !== null
                ? `${sensor.sensor_value}°C`
                : "N/A"}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default AcSensorMap;
