import { apiSlice } from "../api/apiSlice";

export const acBranchWiseAcDashboard = apiSlice.injectEndpoints({
  tagTypes: ["ac-list"],
  endpoints: (builder) => ({
    getBranchWiseAcList: builder.query({
      query: (branchId) => `/apiV2/ac/ac-last-state/${branchId}`,
      providesTags: ["ac-list"],
    }),
    // createAcSchedule: builder.mutation({
    //   query: (data) => ({
    //     url: "/apiV2/ac/ac-schedule",
    //     method: "POST",
    //     body: data,
    //   }),
    //   invalidatesTags: ["ac"],
    // }),
    // updateFish: builder.mutation({
    //   query: (data) => ({
    //     url: "/apiV2/fish",
    //     method: "POST",
    //     body: data,
    //   }),
    //   invalidatesTags: ["ac"],
    // }),
    acOnOff: builder.mutation({
      query: (data) => ({
        url: "/apiV2/ac/on-off",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["ac-list"],
    }),
    acModeSwitch: builder.mutation({
      query: (data) => ({
        url: "/apiV2/ac/schedule-mode-change",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["ac-list"],
    }),
    acSensorMap: builder.mutation({
      query: (data) => ({
        url: "/apiV2/ac/ac-position",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["ac-list"],
    }),
    acTempSensorMap: builder.mutation({
      query: (data) => ({
        url: "/apiV2/ac/sensor-position",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["ac-list"],
    }),

    getAcSensorList: builder.query({
      query: (branchId) => `/apiV2/ac/sensor-position/${branchId}`,
      providesTags: ["ac-list"],
    }),
  }),
});

export const {
  useGetBranchWiseAcListQuery,
  useAcOnOffMutation,
  useAcModeSwitchMutation,
  useAcSensorMapMutation,
  useGetAcSensorListQuery,
  useAcTempSensorMapMutation,
} = acBranchWiseAcDashboard;
