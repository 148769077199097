import React, { useContext } from "react";
import { ThemeContext } from "../../../context/ThemeContext";
import useTitle from "../../../hooks/useTitle";
import styles from "./acDashboard.module.css";
import AcCard from "./AcCard";
import { Scale } from "chart.js";
import { useLocation, useParams } from "react-router-dom";
import { useGetBranchWiseAcListQuery } from "../../../features/ac/acBranchWiseAcDashboard";

function AcDashboard() {
  useTitle("Ac Dashboard");
  const theme = useContext(ThemeContext);
  const { branchId } = useParams();
  const location = useLocation();

  const searchParams = new URLSearchParams(location?.search);

  // Get individual search params
  const branchName = searchParams.get("branch_name");

  const { data: branchWiseAcList, isLoading } =
    useGetBranchWiseAcListQuery(branchId);

  if (!isLoading) {
    console.log(branchWiseAcList);
  }

  return (
    <div>
      <div className={`container-fluid ${theme}`}>
        <div className="card theme-background">
          <div
            className={`theme-text ${styles.top_bar}`}
            style={{ padding: "10px", fontSize: "18px" }}
          >
            <div>{branchName}</div>
            {/* <div>Last Updated : 3 Minuites ago</div> */}
            {/* <div className={`${styles.top_bar_right_section}`}>
              <div>Turn Off ACs</div>
              <div>
                <div className={`${styles.top_bar_switch}`}>
                  <label class="switch">
                    <input type="checkbox" />
                    <span class="slider round"></span>
                  </label>
                </div>
              </div>
            </div> */}
          </div>
        </div>
        <div className="row ">
          {branchWiseAcList?.map((acInfo) => {
            return (
              <div className="col-sm-12 col-md-6 col-lg-4 col-xl-3">
                <AcCard acInfo={acInfo} />
              </div>
            );
          })}
          {/* <div className="col-sm-12 col-md-6 col-lg-4 col-xl-3">
            <AcCard />
          </div> */}
          {/* <div className="col-sm-12 col-md-6 col-lg-4 col-xl-3">
            <AcCard />
          </div>
          <div className="col-sm-12 col-md-6 col-lg-4 col-xl-3">
            <AcCard />
          </div>
          <div className="col-sm-12 col-md-6 col-lg-4 col-xl-3">
            <AcCard />
          </div>
          <div className="col-sm-12 col-md-6 col-lg-4 col-xl-3">
            <AcCard />
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default AcDashboard;
